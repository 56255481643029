import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from '@gq/ui/common/core';
import { LocalStorageService, StorageKey, StorageService } from '@gq/ui/services';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    SocketIoModule.forRoot({
      url: environment.websocketUrl,
      options: { transports: ['websocket'] },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: (storage: StorageService) => ({
          tokenGetter: () => storage.load(StorageKey.AccessToken),
          disallowedRoutes: ['/forgot-password', '/register', '/login', '/'],
        }),
        deps: [StorageService],
      },
    }),
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('@gq/ui/views/home').then((lib) => lib.HomeModule),
        },
        {
          path: 'profile',
          loadChildren: () => import('@gq/ui/views/profile').then((lib) => lib.ProfileModule),
        },
        {
          path: 'queue',
          loadChildren: () => import('@gq/ui/views/queue').then((lib) => lib.QueueModule),
        },
        {
          path: 'login',
          loadChildren: () => import('@gq/ui/views/login').then((lib) => lib.LoginModule),
        },
      ],
      // { initialNavigation: 'enabled' }
    ),
  ],
  providers: [
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
