import type { StorageKey, StorageTypes } from './storage.service';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends StorageService {
  store<K extends StorageKey>(key: K, data: StorageTypes[K]): void {
    localStorage.setItem(this.wrapStorageKey(key), JSON.stringify(data));
  }

  load<K extends StorageKey>(key: K): StorageTypes[K] | null {
    const wrappedKey = this.wrapStorageKey(key);
    try {
      const strData = localStorage.getItem(wrappedKey);
      return JSON.parse(strData) || null;
    } catch (e) {
      return null;
    }
  }

  remove(key: StorageKey): void {
    localStorage.removeItem(this.wrapStorageKey(key));
  }

  clear(): void {
    localStorage.clear();
  }

  protected wrapStorageKey(key: StorageKey): string {
    return `GameQueue::${key}`;
  }
}
