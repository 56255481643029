import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

class LetContext<T> {
  gqLet: T = null;
  $implicit: T = null;
}

@Directive({ selector: '[gqLet]' })
export class LetDirective<T> implements OnInit {
  private context: LetContext<T> = new LetContext<T>();

  @Input()
  set gqLet(value: T) {
    this.context.$implicit = this.context.gqLet = value;
  }

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<LetContext<T>>) {}

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
