import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

export enum TabVisibility {
  Visible = 'Visible',
  Hidden = 'Hidden',
}

@Injectable({ providedIn: 'root' })
export class BrowserTabService {
  visibilityChange$: Observable<TabVisibility>;
  hiddenEvent$: Observable<void>;
  visibleEvent$: Observable<void>;

  constructor() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document['msHidden'] !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document['webkitHidden'] !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    this.visibilityChange$ = fromEvent(document, visibilityChange)
      .pipe(
        map(() => document[hidden] ? TabVisibility.Hidden : TabVisibility.Visible),
        share(),
      );

    this.hiddenEvent$ = this.visibilityChange$.pipe(
      filter(visibility => visibility === TabVisibility.Hidden),
      map(() => { return; }),
    );

    this.visibleEvent$ = this.visibilityChange$.pipe(
      filter(visibility => visibility === TabVisibility.Visible),
      map(() => { return; }),
    );
  }
}
