import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import type { AuthResponsePayload, LoginRequestPayload } from '@gq/api-interfaces';
import type { Observable, Subscription} from 'rxjs';
import { BehaviorSubject, timer } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { BrowserTabService } from '../browser-tab/browser-tab.service';
import { StorageKey, StorageService } from '../storage/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private accessToken: string;
  private refreshToken: string;
  private refreshSub: Subscription;
  private username$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  currentUsernameChanges$: Observable<string> = this.username$.asObservable();

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private browserTabService: BrowserTabService,
  ) {
    this.init();
  }

  async init() {
    this.accessToken = this.storage.load(StorageKey.AccessToken);
    this.refreshToken = this.storage.load(StorageKey.RefreshToken);
    await this.refresh(this.accessToken, this.refreshToken);

    this.listenForWindowFocus();
  }

  async login(username: string, password: string): Promise<void> {
    const payload: LoginRequestPayload = {
      username,
      password,
      refreshToken: this.refreshToken,
    };
    const result: AuthResponsePayload = await this.http.post<AuthResponsePayload>('/api/auth/login', payload).toPromise();

    this.updateSession(result);

    await this.router.navigate(['/', 'queue', this.username$.value]);
  }

  async logout(): Promise<void> {
    this.storage.clear();
    this.username$.next(null);
    this.accessToken = null;

    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }

    // no need to hit the endpoint if we don't have a refresh token tokens
    if (!this.refreshToken) {
      return;
    }

    try {
      await this.http.post<void>('/api/auth/logout', {
        refreshToken: this.refreshToken || undefined,
      }).toPromise();
    } finally {
      this.refreshToken = null;
    }
  }

  async refresh(accessToken: string, refreshToken: string): Promise<void> {
    if (!accessToken || !refreshToken) {
      await this.logout();
      return;
    }

    try {
      const result: AuthResponsePayload = await this.http.post<AuthResponsePayload>('/api/auth/refresh', {
        refreshToken,
      }).toPromise();

      this.updateSession(result);
    } catch (err) {
      const httpError: HttpErrorResponse = err;
      if (400 <= httpError?.status && httpError?.status < 500) {
        await this.logout();
      }
    }
  }

  getCurrentUsername(): string {
    return this.username$.value;
  }

  async initLoadRefresh(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    return this.refresh(accessToken, refreshToken);
  }

  private updateSession(result: AuthResponsePayload): void {
    this.accessToken = result.accessToken;
    this.refreshToken = result.refreshToken;
    this.username$.next(result.username);

    this.storage.store(StorageKey.AccessToken, this.accessToken);
    this.storage.store(StorageKey.RefreshToken, this.refreshToken);

    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }

    const expires = this.jwtHelper.getTokenExpirationDate();

    // 2 minutes before it expires
    const expiresTimeout = expires.getTime() - new Date().getTime() - 1000 * 2 * 60;

    if (expiresTimeout < 0) {
      console.error('Token should not be expiring in the past...?', expiresTimeout);
      return;
    }

    // refresh 5 minutes before expiration
    this.refreshSub = timer(expiresTimeout)
      .subscribe(() => this.refresh(this.accessToken, this.refreshToken));
  }

  private listenForWindowFocus() {
    this.browserTabService.visibleEvent$.pipe(
      filter(() => this.jwtHelper.isTokenExpired(undefined, 30)),
      throttleTime(29 * 1000),
    )
      .subscribe(() => {
        this.refresh(this.storage.load(StorageKey.AccessToken), this.storage.load(StorageKey.RefreshToken));
      });
  }
}
