import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HumanStatusPipe } from './human-status/human-status.pipe';
import { HumanTimePipe } from './human-time/human-time.pipe';
import { LetDirective } from './let/let.directive';
import { MarkdownPipe } from './markdown/markdown.pipe';
import { SafeHtmlComponent } from './safe-html/safe-html.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HumanTimePipe, HumanStatusPipe, MarkdownPipe, SafeHtmlComponent, LetDirective],
  exports: [HumanTimePipe, HumanStatusPipe, MarkdownPipe, SafeHtmlComponent, LetDirective],
})
export class CoreModule {}
