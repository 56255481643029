import { Injectable, NgZone } from '@angular/core';
import { merge } from 'lodash';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { StorageKey, StorageService } from '../storage/storage.service';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export interface GameState {
  open: boolean;
  editing: boolean;
}

export interface QueueState {
  [id: string]: GameState;
}

export interface UiState {
  scrollPosition: number;
  currentQueue: string;
  queueState: QueueState;
}

@Injectable({ providedIn: 'root' })
export class UiStateService {
  private state: UiState = {
    scrollPosition: 0,
    currentQueue: null,
    queueState: {},
  };

  private defaultGameState: GameState = {
    open: false,
    editing: false,
  };

  private ready = false;

  private updateEvent$ = new Subject<UiState>();

  constructor(private storage: StorageService, private zone: NgZone) {}

  flush(): void {
    return;
  }

  getGameState(gameId: string): GameState {
    return this.state.queueState[gameId] || { ...this.defaultGameState };
  }

  restore(): void {
    if (this.ready) {
      return;
    }
    this.ready = true;

    this.start();
    const restoredState = this.storage.load(StorageKey.UiState);

    if (restoredState) {
      this.state = restoredState;
      this.updateEvent$.next(this.state);
    }

    setTimeout(() => {
      window.scrollTo({
        top: this.state.scrollPosition,
      });
    });
  }

  updateGameState(id: string, partial: Partial<GameState>): void {
    if (!this.state.queueState[id]) {
      partial = { ...this.defaultGameState, ...partial };
    }

    this.update({
      queueState: {
        [id]: {
          ...partial,
        },
      },
    });
  }

  clear() {
    this.storage.remove(StorageKey.UiState);
  }

  private update(partial: DeepPartial<UiState>): void {
    this.state = merge({ ...this.state }, partial);
    this.updateEvent$.next(this.state);
  }

  private start(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(document, 'scroll')
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.update({ scrollPosition: window.scrollY });
        });

      this.updateEvent$
        .pipe(
          filter(() => this.ready),
          debounceTime(1000),
        )
        .subscribe((state) => {
          this.storage.store(StorageKey.UiState, state);
        });
    });
  }
}
