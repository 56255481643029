import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import type { ParamMap, RouterEvent } from '@angular/router';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@gq/ui/services';
import type { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'gq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  username$: Observable<string> = this.authService.currentUsernameChanges$;

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.listenForTitleChanges();
  }

  private listenForTitleChanges() {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route: ActivatedRoute) => route.paramMap),
        map((paramMap: ParamMap) => paramMap.get('username')),
        map((username: string) => (username ? `${username} | ` : '') + 'GameQueue'),
      )
      .subscribe((title: string) => this.titleService.setTitle(title));
  }

}
