import { Injectable } from '@angular/core';
import type { UiState } from '../ui-state/ui-state.service';

export enum StorageKey {
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  UiState = 'UiState',
}

export interface StorageTypes {
  [StorageKey.AccessToken]: string;
  [StorageKey.RefreshToken]: string;
  [StorageKey.UiState]: UiState;
}

@Injectable({ providedIn: 'root' })
export abstract class StorageService {
  abstract store<K extends StorageKey>(key: K, data: StorageTypes[K]): void;

  abstract load<K extends StorageKey>(key: K): StorageTypes[K] | null;

  abstract remove(key: StorageKey): void;

  abstract clear(): void;
}
